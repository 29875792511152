// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock.es6';

// const header = document.querySelector('.header');
// const hamburger = document.querySelector('.header__hamburger');
// const nav = document.querySelector('.header__nav');

// let isMenuOpened = false;

// window.addEventListener('scroll', () => {
//   if (scrollY > window.innerHeight) {
//     header.classList.add('filled');
//   } else {
//     header.classList.remove('filled');
//   }
// });

// function openMenu() {
//   header.classList.add('menu-opened');
//   hamburger.classList.add('active');
//   disableBodyScroll(nav);
//   isMenuOpened = true;
// }

// function closeMenu() {
//   header.classList.remove('menu-opened');
//   hamburger.classList.remove('active');
//   enableBodyScroll(nav);
//   isMenuOpened = false;
// }

// hamburger.addEventListener('click', () => isMenuOpened ? closeMenu() : openMenu());

// SP nav
document.addEventListener("DOMContentLoaded", function() {
  const navButton = document.querySelector('.js-nav-bt');
  const navContent = document.querySelector('.js-nav-content');
  navButton.addEventListener('click', function() {
    if (navContent.classList.contains('is-navOn')) {
      navContent.classList.remove('is-navOn');
      this.classList.remove('is-navOn');
    } else {
      navContent.classList.add('is-navOn');
      this.classList.add('is-navOn');
    }
  });
  document.querySelectorAll('.js-nav-bt.is-navOn, .js-nav-logo.is-navOn, .js-nav-link').forEach(function(element) {
    element.addEventListener('click', function() {
      document.querySelectorAll(".js-nav-content.is-navOn, .js-nav-bt.is-navOn").forEach(function(target) {
        target.classList.remove('is-navOn');
      });
    });
  });
});

// sp nav sub Dropdown
document.addEventListener("DOMContentLoaded", function() {
  if (window.innerWidth < 768) {
    const navSub = document.querySelector('.js-nav-sub');
    const navSubButton = document.querySelector('.js-nav-sub-bt');
    navSubButton.addEventListener('click', function() {
      if (navSub.classList.contains('is-navSubOn')) {
        navSub.classList.remove('is-navSubOn');
        navSubButton.classList.remove('is-navSubOn');
      } else {
        navSub.classList.add('is-navSubOn');
        navSubButton.classList.add('is-navSubOn');
      }
    });
  }
});
