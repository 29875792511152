import '../scss/style.scss';
import './lozad';
import './aos';
import './header';
import './swiper';
import './dropdown';
import './lowermv';

const headerHeight = document.querySelector('.header').offsetHeight;
let urlHash = location.hash;
if (urlHash) {
  setTimeout(() => {
    let target = document.querySelector(urlHash);
    let position = target.getBoundingClientRect().top + window.pageYOffset - headerHeight;
    window.scrollTo(0, position);
  }, 1500);
}

const viewmore = document.querySelector('.js-viewmore');
viewmore.addEventListener('click', () => {
  const viewmoreHide = document.querySelectorAll('.js-viewmoreHide');
  viewmore.classList.add('is-hide');
  viewmoreHide.forEach((item) => {
    item.classList.add('is-show');
  });
});
